.callout-section {
    position: relative;
    padding: 8.5rem 0 10.5rem 0;
    z-index: 3;
    width: 100%;
    background-color: #fff;
    display: block;
    display: -webkit-flex;
    display: -ms-flexbox;
    overflow: hidden;
    @include respond-to('medium-large') {
        padding: 8.5rem 0 18.5rem 0;
        display: flex;
    }
    .callout-section-left {
        flex: 1;
        &-content {
            height: 100%;
            color: #fff;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            &-holder {
                width: 100%;
            }
            @include respond-to('medium-large') {
                height: 62.94rem;
            }
        }
        @include respond-to('medium-large') {
            height: 62.94rem;
            &[data-module="in-view"] {
                opacity: 0;
                transform: translateY(0);
            }
            
            &[data-is="visible"] {
                opacity: 1;
                transform: translateY(#{vr(3)});
                transition: all ($timing-base * 3) $easing-base;
            }
        }

        .callout-section-left-content-holder {
            margin: 6rem;
            @include respond-to('extra-small') {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }

        .callout-section-left-content-holder a.u-button {
            border-radius: 50px;
        }

        .callout-section-left-content-holder .callout-section-title {
            font-style: normal;
            font-weight: 300;
            text-align: center;
            @include respond-to('medium-large') {
                font-size: 36px;
                line-height: 49px;
            }
            @include respond-to('extra-small') {
                font-size: 30px;
                line-height: 40px;
            }
        }

        .callout-section-left-content-holder .callout-section-description {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            text-align: left;
            margin: 40px 0px;
            @include respond-to('extra-small') {
                font-weight: 300;
                font-size: 17px;
                text-align: left;
            }
            @include respond-to('medium-large') {
                text-align: center;
                margin: 37px 15px;
            }
        }
    }

    .callout-section-right{
        @include respond-to('medium-large') {
            flex: 1;
            height: 64.34rem;
            margin-top: 63px;
            overflow: hidden;

	    &[data-module="in-view"] {
                opacity: 0;
                transform: translateY(#{vr(3)});
                transition: all ($timing-base * 3) $easing-base;
            }
            
            &[data-is="visible"] {
                opacity: 1;
                transform: translateY(0);
            }
        }

        position: relative;
        .homecontent-stripe{
            line-height: 0;
            height: 26rem;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          @include respond-to('medium'){
            height: 34rem;
          }
          @include respond-to('medium-large'){
            height: 100%;
          }
        }
        &-text{
            width: 100%;
            padding: 20px;
            background-color: #fff;
        }
    }
}
