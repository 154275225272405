.site-nav-utility {
  @include respond-to('medium-large') {
    background-color: #fff;
    height: $header-height--large;
    left: 0;
    padding: 2.2rem $layout-side-padding 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: $header-height--large * -1;
    z-index: 1;
  }
}

.site-nav-utility-container {
  @include respond-to('medium-large') {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 153rem;
  }
}


/*
  --------------------
  My Rotary/Club Finder
  --------------------
*/

.site-nav-utility-links {
  @include list-unstyled;

  &.-small {
    @include clearfix;
    margin-top: 0;
    max-width: 60rem;
    opacity: 0;
    transition:
      opacity $timing-base $easing-base,
      visibility 0s linear $timing-base;
    visibility: collapse;

    [data-mobile="navigation"] & {
      opacity: 1;
      transition:
        opacity $timing-base $easing-base,
        visibility 0s linear 0s;
      visibility: visible;
      margin: 0 7rem 0 7rem;
      border-top: 1px groove #E8EBEE;
    }

    @include respond-to('medium-large') {
      display: none;
    }

    a {
      @include font-15;
      display: block;
      padding: 1.2rem 0;
      text-align: center;
    }
  }

  &.-large {
    display: none;
    margin: 0;

    @include respond-to('medium-large') {
      display: block;
      padding-top: 2px;

      button {
        position: relative;
        height: auto;
        padding-top: 0;
        margin-right: 1rem;
        margin-left: 1rem;
        overflow:visible;
      }
    }

    .site-nav-toggle-search-icon {
      top: 10px;
      left: -10px;
      margin-left: -5.5px;

      &::after {
        width: 6px;
      }

      &::before {
        height: 11px;
        width: 11px;
        top: 3px;
      }
    }

    a {
      @include font-14;
      display: block;
    }

    li {
      display: inline-block;
      line-height: vr(6);
      margin: 0;

      + li {
        margin-left: 2.1rem;

        a::before {
          background-color: palette(gray, light);
          content: '';
          display: block;
          height: 12px;
          left: -1.1rem;
          margin-top: -6px;
          top: 50%;
          position: absolute;
          width: 1px;
        }

        + li {
          button::before {
            background-color: palette(gray, light);
            content: '';
            display: block;
            height: 12px;
            left: -2.5rem;
            margin-top: -6px;
            top: 50%;
            position: absolute;
            width: 1px;
          }
        }
      }
    }
  }

  a {
    color: palette(blue);
    font-weight: $font-weight--bold;
    position: relative;
    text-transform: uppercase;

    @include hover {
      @include standard-hover;
      color: palette(blue);

      path {
        fill: palette(blue);
      }
    }
  }

  path {
    fill: #bbc1c6;
    transition: $transition--basic;
  }
}


/*
  --------------------
  Search
  --------------------
*/

.site-nav-utility-search {
  border-top: 1px solid palette(gray, x-light);
  display: inline-block;
  left: 0;
  opacity: 1;
  overflow: hidden;
  padding-bottom: 18px; // adds padding for shadow
  position: absolute;
  top: 100%;
  transition:
  opacity $timing-base $easing-base,
  visibility 0s linear 0s;
  visibility: collapse;
  width: 100%;

  // small screen search open state
  [data-mobile="search"] & {
    top: 0px;
    opacity: 1;
    transition:
      opacity $timing-base $easing-base,
      visibility 0s linear 0s;
    visibility: visible;
  }

  @include respond-to('medium-large') {
    width: 20%;
    margin-top: 0.2%;
    margin-right: 1%;
    position: relative;
    visibility: visible;
    display: none;
  }

  form {
    background-color: #fff;
    box-shadow: 0 0 30px rgba(#000, .2);
    padding: 2rem;

    @include respond-to('medium-large') {
      box-shadow: none;
      padding: 0;
    }
  }

  button,
  input[type="submit"] {
    appearance: none;
    background-color: transparent;
    border: none;
    height: calc(#{vr(6)} - .6rem);
    margin: 0;
    padding: 0;
    position: absolute;
    right: 2.3rem;
    top: 2.3rem;
    width: calc(#{vr(6)} - .6rem);

    @include respond-to('medium-large') {
      right: 0.3rem;
      top: 0.1rem;
    }

    path {
      fill: palette(blue);
    }

    svg {
      height: 1.4rem;
      left: 50%;
      margin: -.7rem 0 0 -.7rem;
      position: absolute;
      top: 50%;
      width: 1.4rem;
    }
  }

  input[type="search"],
  input[type="text"] {
    height: vr(6);
    padding: 0 vr(6) 0 .6rem;

    &::-ms-clear {
      display: none;
    }

    @include respond-to('medium-large') {
      height: 3rem;
    }
  }
}


/*
  --------------------
  Join/Give
  --------------------
*/

.site-nav-utility-ctas {
  @include clearfix;
  @include font-16;
  @include list-unstyled;
  background-color: palette(gray, xx-light);
  margin: 0;
  max-width: none;
  opacity: 0;
  text-align: center;
  text-transform: uppercase;
  transition:
    opacity $timing-base $easing-base,
    visibility 0s linear $timing-base;
  visibility: collapse;
  width: 100%;
  z-index: 10;

  [data-mobile="navigation"] & {
    opacity: 1;
    transition:
      opacity $timing-base $easing-base,
      visibility 0s linear 0s;
    visibility: visible;
    .u-button.-slim {
      border-radius:unset;
    }
    .u-button, .site-nav .u-button {
      border: 1px solid #d7dae0 !important;
    }
  }

  @include respond-to('medium-large') {
    background-color: transparent;
    display: inline-block;
    height: auto;
    opacity: 1;
    position: static;
    visibility: visible;
    width: auto;

    .u-button.-slim {
      padding: 0.8rem 2rem;
      margin-top: 2px;
      border-radius: 2rem;
    }
  }

  a {
    display: block;
    line-height: normal;
    min-width: 13rem;
  }

  li {
    display: block;
    float: left;
    margin: 0;
    width: 50%;

    @include respond-to('medium-large') {
      display: inline-block;
      float: none;
      width: auto;

      .u-button.-slim {
        padding: 0.5rem 3rem;
        margin-top: 2px;
        border-radius: 2rem;
      }

      + li {
        border: none;
        margin-left: 2.1rem;
      }
    }
  }
}
